import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../../../sections/Navbar/Navbar'
import Footer from '../../../sections/Footer'
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import "../../../styles/AdminPage.scss";

function InstallCoachelloApp({ data }: any) {
    const info = data.prismicFiveStepsToInstallTheCoachelloAppForSlack.dataRaw
    const infoImg = data.prismicFiveStepsToInstallTheCoachelloAppForSlack.data
    return (
        <div>
            <Navbar />
            <div className='AdminPage'>
                <div className='AdminPage-title'>
                    <PrismicRichText field={info.title} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.updated} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.title1} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte}
                        components={{
                            hyperlink: ({ node, children, key }) => (
                                <PrismicLink
                                    field={node.data}
                                    className="link"
                                >
                                    {children}
                                </PrismicLink>
                            )
                        }}
                    />
                    <img className="AdminPage-image" src={infoImg.im1.url} alt={infoImg.im1.alt} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte0} />
                    <img className="AdminPage-image" src={infoImg.im21.url} alt={infoImg.im21.alt} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.title2} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte1} />
                    <img className="AdminPage-image" src={infoImg.im3.url} alt={infoImg.im3.alt} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte2} />
                    <img className="AdminPage-image" src={infoImg.im4.url} alt={infoImg.im4.alt} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte3} />
                    <img className="AdminPage-image" src={infoImg.im5.url} alt={infoImg.im5.alt} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.title3} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte4} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.title4} />
                </div>
                <div className='AdminPage-content'>
                    <PrismicRichText field={info.texte5}
                        components={{
                            hyperlink: ({ node, children, key }) => (
                                <PrismicLink
                                    field={node.data}
                                    className="link"
                                >
                                    {children}
                                </PrismicLink>
                            )
                        }} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default InstallCoachelloApp;

export const query = graphql`
query InstallCoachelloApp {
  prismicFiveStepsToInstallTheCoachelloAppForSlack(lang: {eq: "en-us"}) {
    dataRaw
    data {
      im1 {
        alt
        url
      }
      im21 {
        alt
        url
      }
      im3 {
        alt
        url
      }
      im4 {
        alt
        url
      }
      im5 {
        alt
        url
      }
      logo {
        alt
        url
      }
    }
  }
}
`